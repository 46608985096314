import Databroker from "@itm21st/databroker";
import config from "../config";
import { getToken } from "../services/StorageProvider";
import { useState } from "react";

const baseURL = `${config.databrokerApiUrl}/internal`;

const useDatabroker = (): Databroker => {
    const [databroker] = useState<Databroker>(() => {
        return new Databroker({ baseURL, token: () => Promise.resolve(getToken() as string) });
    });
    return databroker;
};

export default useDatabroker;
