import { UserData, decode } from "@itm21st/auth-core";

import { createContext } from "react";
import { getToken } from "./StorageProvider";

export interface AuthContextProps {
    user: UserData | null;
    onSetUser: (user: UserData | null) => void;
    onLogout: () => void;
    userHasRole: (role: string) => boolean;
    userHasAnyRole: (roles: string[]) => boolean;
}

export default createContext<AuthContextProps>({
    user: null,
    onSetUser: () => {
        throw new Error("not implemented");
    },
    onLogout: () => {
        throw new Error("not implemented");
    },
    userHasAnyRole: () => false,
    userHasRole: () => false
});

export const initializeAuthContext = (): UserData | null => {
    const token: string | null = getToken();
    if (token) {
        const decodedToken = decode(token);
        const currentTime = Math.floor(Date.now() / 1000);
        if (decodedToken.exp && currentTime < decodedToken.exp) {
            return decodedToken;
        }
    }
    return null;
};
