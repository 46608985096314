import "semantic-ui-css/semantic.min.css";
import "@itm21st/faceted-filter/dist/style.css";
import "@itm21st/navbar/dist/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./css/kendo.scss";
import "./css/app.scss";

import AuthContext, { AuthContextProps, initializeAuthContext } from "./services/AuthContext";
import React, { useState } from "react";
import { UserData, hasAnyRole, hasRole } from "@itm21st/auth-core";
import { clearToken, getToken } from "./services/StorageProvider";

import AppContext from "./services/AppContext";
import AppRoutes from "./AppRoutes";
import { Client } from "./types";
import GlobalSearch from "./components/GlobalSearch/GlobalSearch";
import IdleTimeout from "@itm21st/idle-timeout-react";
import { Link } from "react-router-dom";
import Navbar from "@itm21st/navbar";
import { ToastContainer } from "react-toastify";
import config from "./config";

const App: React.FC = () => {
    const [user, setUser] = useState<UserData | null>(initializeAuthContext());
    const [clientSearchValue, setClientSearchValue] = useState<Client>({} as Client);

    const logout = () => {
        clearToken();
        setUser(null);
    };

    const contextValue: AuthContextProps = {
        user,
        onSetUser: setUser,
        onLogout: logout,
        userHasAnyRole: (roles: string[]) => hasAnyRole(user!, roles, "connect"),
        userHasRole: (role: string) => hasRole(user!, role, "connect")
    };

    return (
        <AppContext.Provider value={{ clientSearchValue, setClientSearchValue }}>
            {user && (
                <Navbar
                    siteMapApiUrl={config.siteMapApiUrl}
                    host="uw"
                    accessToken={getToken() as string}
                    accessTokenName={config.accessTokenName}
                    linkComponent={Link as React.ElementType<{ to: string }>}
                    logout={logout}
                    username={user.username}
                >
                    <div style={{ float: "right", marginRight: 20 }}>
                        <GlobalSearch />
                    </div>
                </Navbar>
            )}
            <AuthContext.Provider value={contextValue}>
                <IdleTimeout onIdle={logout} />
                <ToastContainer theme="colored" />
                <div style={{ margin: "5px 10px 10px 10px" }}>
                    <AppRoutes />
                </div>
            </AuthContext.Provider>
        </AppContext.Provider>
    );
};

export default App;
