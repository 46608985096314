import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense, lazy } from "react";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";

const AnalyticsDashboard = lazy(() => import("./components/AnalyticsDashboard/AnalyticsDashboard"));
const AuthorizationCallback = lazy(() => import("./components/AuthorizationCallback/AuthorizationCallback"));
const ClientPage = lazy(() => import("./components/ClientPage/ClientPage"));
const ClientSearch = lazy(() => import("./components/ClientSearch/ClientSearch"));
const CodeConditions = lazy(() => import("./components/UWCode/CodeConditions"));
const Compare = lazy(() => import("./components/UWCompare/Compare"));
const ComparePage = lazy(() => import("./components/UWSCompare/ComparePage"));
const CodePage = lazy(() => import("./components/UWSCode/CodePage"));
const CdrQueue = lazy(() => import("./components/CdrQueue/CdrQueue"));
const CdrRequest = lazy(() => import("./components/CdrRequest/CdrRequest"));
const DataCleanupReport = lazy(() => import("./components/DataCleanupReport/DataCleanupReport"));
const SharedDashboard = lazy(() => import("./components/SharedDashboard/SharedDashboard"));
const IDIResultsPage = lazy(() => import("./components/IDIResultModal/IDIResultPage"));
const ImageViewer = lazy(() => import("./components/ImageViewer/ImageViewer"));
const Icd10Management = lazy(() => import("./components/Icd10Management/Icd10Management"));
const InsuredSearch = lazy(() => import("./components/InsuredSearch/InsuredSearch"));
const LECalculator = lazy(() => import("./components/LECalculator/LECalculator"));
const LEWorksheet = lazy(() => import("./components/LEWorksheet/LEWorksheet"));
const LRMReport = lazy(() => import("./components/LRM/LRMReport"));
const MassCalc = lazy(() => import("./components/MassCalc/MassCalc"));
const MedRecHandlingQueue = lazy(() => import("./components/MedRecHandlingQueue/MedRecHandlingQueue"));
const MergeClients = lazy(() => import("./components/MergeClients/MergeClients"));
const MergeInsured = lazy(() => import("./components/MergeInsured/MergeInsured"));
const MessageInbox = lazy(() => import("./components/MessageInbox/MessageInbox"));
const MyCaseload = lazy(() => import("./components/MyCaseload/MyCaseload"));
const OnlineSubmissions = lazy(() => import("./components/OnlineSubmissions"));
const RequestSearch = lazy(() => import("./components/RequestSearch/RequestSearch"));
const ReviewCodedPages = lazy(() => import("./components/ReviewCodedPages/ReviewCodedPages"));
const SubmitDetails = lazy(() => import("./components/SubmitQueue/SubmitDetails/SubmitDetails"));
const SubmitQueue = lazy(() => import("./components/SubmitQueue/SubmitQueue"));
const ReportGenerator = lazy(() => import("./components/ReportGenerator/ReportGenerator"));
const UserDetails = lazy(() => import("./components/User/User"));
const UserSearch = lazy(() => import("./components/UserSearch/UserSearch"));
const UWSManagementQueue = lazy(() => import("./components/UWSManagementQueue"));
const UWQueue = lazy(() => import("./components/UWQueue"));

const AppRoutes: React.FC = () => {
    return (
        <Suspense fallback={<div>loading...</div>}>
            <Routes>
                <Route path="/callback" Component={AuthorizationCallback} />
                <Route path="/AnalyticsDashboard" element={<PrivateRoute component={AnalyticsDashboard} />} />
                <Route path="/Client/:ClientID" element={<PrivateRoute component={ClientPage} />} />
                <Route path="/ClientSearch" element={<PrivateRoute component={ClientSearch} />} />
                <Route path="/uw-code/:RequestID" element={<PrivateRoute component={CodeConditions} />} />
                <Route path="/uw-compare/:RequestID?" element={<PrivateRoute component={Compare} />} />
                <Route path="/ComparePage/:provider/:ID/:FilePageID?" element={<PrivateRoute component={ComparePage} />} />
                <Route path="/CodePage/:provider/:ID/:FilePageID?" element={<PrivateRoute component={CodePage} />} />
                <Route path="/CdrQueue" element={<PrivateRoute component={CdrQueue} />} />
                <Route path="/CdrRequest/:CdrRequestID" element={<PrivateRoute component={CdrRequest} />} />
                <Route path="/DataCleanupReport" element={<PrivateRoute component={DataCleanupReport} />} />
                <Route path="/SharedDashboard" element={<PrivateRoute component={SharedDashboard} />} />
                <Route path="/IDIResults/:submitID" element={<PrivateRoute component={IDIResultsPage} />} />
                <Route path="/ImageViewer/:provider/:uuid" element={<PrivateRoute component={ImageViewer} />} />
                <Route path="/Icd10Management" element={<PrivateRoute component={Icd10Management} />} />
                <Route path="/InsuredSearch" element={<PrivateRoute component={InsuredSearch} />} />
                <Route path="/le-calculator/:RequestID?" element={<PrivateRoute component={LECalculator} />} />
                <Route path="/LEWorksheet/:ID" element={<PrivateRoute component={LEWorksheet} />} />
                <Route path="/LRMReport" element={<PrivateRoute component={LRMReport} />} />
                <Route path="/MassCalc" element={<PrivateRoute component={MassCalc} />} />
                <Route path="/MedRecHandlingQueue" element={<PrivateRoute component={MedRecHandlingQueue} />} />
                <Route path="/MergeClients" element={<PrivateRoute component={MergeClients} />} />
                <Route path="/MergeInsured/:ins1?/:ins2?" element={<PrivateRoute component={MergeInsured} />} />
                <Route path="/MessageInbox" element={<PrivateRoute component={MessageInbox} />} />
                <Route path="/MyCaseload" element={<PrivateRoute component={MyCaseload} />} />
                <Route path="/OnlineSubmissions/:SubmitID?" element={<PrivateRoute component={OnlineSubmissions} />} />
                <Route path="/RequestSearch" element={<PrivateRoute component={RequestSearch} />} />
                <Route path="/ReviewCodedPages/:provider/:ID" element={<PrivateRoute component={ReviewCodedPages} />} />
                <Route path="/SubmitDetails/:provider/:ID" element={<PrivateRoute component={SubmitDetails} />} />
                <Route path="/SubmitQueue/:source?" element={<PrivateRoute component={SubmitQueue} />} />
                <Route path="/ReportGenerator/:report?" element={<PrivateRoute component={ReportGenerator} />} />
                <Route path="/UWQueue" element={<PrivateRoute component={UWQueue} />} />
                <Route path="/UWSManagementQueue" element={<PrivateRoute component={UWSManagementQueue} />} />
                <Route path="/User" element={<PrivateRoute exact component={UserSearch} />} />
                <Route path="/User/:authID" element={<PrivateRoute component={UserDetails} />} />
                <Route path="/" element={<Navigate to="/RequestSearch" replace />} />
                <Route path="*" element={<Navigate to="/RequestSearch" replace />} />
            </Routes>
        </Suspense>
    );
};

export default AppRoutes;
