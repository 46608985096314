import { Grid, Icon, Message } from "semantic-ui-react";

import React from "react";
import { Role } from "../../types";

interface FallbackProps {
    missingRole?: Role;
    missingAllRoles?: Role[];
    missingSomeRole?: Role[];
}

const Fallback: React.FC<FallbackProps> = ({ missingRole, missingAllRoles, missingSomeRole }: FallbackProps) => {
    return (
        <Grid centered>
            <Grid.Column width="8">
                <Message icon warning>
                    <Icon name="exclamation triangle" />
                    <Message.Content>
                        <Message.Header>The page you requested could not be served</Message.Header>
                        {!missingAllRoles && !missingRole && !missingSomeRole && (
                            <>Please check the url you tried to reach. This page does not exist.</>
                        )}
                        {!!missingAllRoles && (
                            <>
                                <p>
                                    Must have all of the following roles to access this page:
                                    <ul>
                                        {missingAllRoles.map((role) => (
                                            <li key={role}>
                                                <b>{role}</b>
                                            </li>
                                        ))}
                                    </ul>
                                    Please contact your manager for access.
                                </p>
                            </>
                        )}
                        {!!missingSomeRole && (
                            <>
                                <p>
                                    Must have one of the following roles to access this page:
                                    <ul>
                                        {missingSomeRole.map((role) => (
                                            <li key={role}>
                                                <b>{role}</b>
                                            </li>
                                        ))}
                                    </ul>
                                    Please contact your manager for access.
                                </p>
                            </>
                        )}
                        {!!missingRole && (
                            <>
                                You are missing the required role, <b>{missingRole}</b>, to access this page. Please contact your manager
                                for access.
                            </>
                        )}
                    </Message.Content>
                </Message>
            </Grid.Column>
        </Grid>
    );
};

export default Fallback;
