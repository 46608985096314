import "./index.css";

import App from "./App";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import config from "./config";
import { createRoot } from "react-dom/client";
import qs from "query-string";
import { storeToken } from "./services/StorageProvider";

const { accessTokenName } = config;
const query = qs.parse(window.location.search);
if (query[accessTokenName]) {
    const token = query[accessTokenName] as string;
    storeToken(token);
    delete query[accessTokenName];
    window.location.search = qs.stringify(query);
}

const container = document.getElementById("root");
const root = createRoot(container as HTMLElement);

root.render(
    <React.StrictMode>
        <Router>
            <App />
        </Router>
    </React.StrictMode>
);
