import { Client } from "../types";
import { createContext } from "react";

export interface AppContextProps {
    clientSearchValue: Client;
    setClientSearchValue: (searchValue: Client) => void;
}

const appContext = createContext<AppContextProps>({
    clientSearchValue: {} as Client,
    setClientSearchValue: (): void => {
        throw new Error("Not implemented"); // must be overridden in App.tsx
    }
});

export default appContext;
