import React, { useContext } from "react";
import { hasAllRoles, hasAnyRole, hasRole } from "@itm21st/auth-core";

import AuthContext from "../../services/AuthContext";
import Fallback from "../Fallback/Fallback";
import { Role } from "../../types";
import config from "../../config";

export interface WithAuthenticationRequiredOptions {
    allowIfHasRole?: Role;
    allowIfHasAllRoles?: Role[];
    allowIfHasAnyRole?: Role[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
const WithAuthenticationRequired = <P extends {}>(
    Component: React.ComponentType<P>,
    options: WithAuthenticationRequiredOptions
): React.FC<P> =>
    function Wrapper(props: P): JSX.Element {
        const currentTime = Math.floor(Date.now() / 1000);
        const { user, onLogout } = useContext(AuthContext);

        if (!user || !user.exp || currentTime > user.exp || !user.appData.connect) {
            onLogout();
            const { origin, pathname, search } = window.location;
            const redirect_uri = encodeURIComponent(`${origin}/callback?return_to=${pathname}${search}`);
            const redirect = `${config.authWebUrl}?redirect_uri=${redirect_uri}`;
            window.location.href = redirect;
            return <p>Redirecting to authentication</p>;
        }

        const { allowIfHasAllRoles, allowIfHasAnyRole, allowIfHasRole } = options;

        if (!!allowIfHasRole && !hasRole(user, allowIfHasRole, "connect")) {
            return <Fallback missingRole={allowIfHasRole} />;
        }

        if (!!allowIfHasAnyRole && !hasAnyRole(user, allowIfHasAnyRole, "connect")) {
            return <Fallback missingSomeRole={allowIfHasAnyRole} />;
        }

        if (!!allowIfHasAllRoles && !hasAllRoles(user, allowIfHasAllRoles, "connect")) {
            return <Fallback missingAllRoles={allowIfHasAllRoles} />;
        }

        return <Component {...props} />;
    };

export default WithAuthenticationRequired;
