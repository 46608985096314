/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import withAuthenticationRequired from "../WithAuthenticationRequired/WithAuthenticationRequired";

interface PrivateRouteProps {
    component: React.ComponentType<any>;
    [key: string]: any;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props: PrivateRouteProps) => {
    const { component, allowIfHasRole, allowIfHasAllRoles, allowIfHasAnyRole, ...rest } = props;

    const Component = withAuthenticationRequired(component, {
        allowIfHasRole,
        allowIfHasAllRoles,
        allowIfHasAnyRole
    });

    return <Component {...rest} />;
};

export default PrivateRoute;
