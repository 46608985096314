import { Card, Input, List } from "semantic-ui-react";
import React, { useCallback, useEffect, useRef, useState } from "react";

import config from "../../config";
import { debounce } from "lodash";
import useDatabroker from "../../hooks/useDatabroker";

const numeral = /^\d+$/;
const ssn = /^\d{3}-/;
const email = /@/;

const linkDict = {
    client: "meddiag/general/Client.aspx?ClientID=",
    request: "meddiag/general/RequestDetails.aspx?RequestID=",
    insured: "insured/",
    medrec: "Servicing/MedicalRecords/Order.aspx?mrid=",
    policy: "Servicing/Policy.aspx?id=",
    portfolio: "Servicing/Portfolio.aspx?id=",
    carrier: "Servicing/CarrierManagement.aspx?CID="
} as { [key: string]: string };

const buildLink = (input: Result) => {
    const { Value, Type, ID } = input;
    const { connectWebUrl: baseUrl, servicingWebUrl } = config;
    const url = linkDict[Type.toLowerCase()];
    return (
        <a href={`${Type.toLowerCase() === "insured" ? servicingWebUrl : baseUrl}/${url}${ID}`} rel="noopener noreferrer" target="_blank">
            {Value}
        </a>
    );
};

interface Result {
    Type: string;
    ID: number;
    Value: string;
}

const GlobalSearch: React.FC = () => {
    const [results, setResults] = useState<Result[]>([]);
    const [searchInput, setSearchInput] = useState<string>("");
    const searchNode = useRef<HTMLDivElement>(null);
    const databroker = useDatabroker();

    const runSearch = useCallback(
        async (input: string) => {
            if (numeral.test(input)) {
                const { data } = await databroker.sproc({
                    objectName: "meddiag.dbo.SearchAnyNumeric",
                    parameters: {
                        input
                    }
                });
                return data || [];
            }
            if (ssn.test(input)) {
                const { data } = await databroker.sproc({
                    objectName: "meddiag.dbo.SearchAnySSN",
                    parameters: {
                        input
                    }
                });
                return data || [];
            }
            if (email.test(input)) {
                const { data } = await databroker.sproc({
                    objectName: "meddiag.dbo.SearchAnyEmail",
                    parameters: {
                        input
                    }
                });
                return data || [];
            }
            if (input.length >= 3) {
                const { data } = await databroker.sproc({
                    objectName: "meddiag.dbo.SearchAnyName",
                    parameters: {
                        input
                    }
                });
                return data || [];
            }
            return [];
        },
        [databroker]
    );

    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = (e: MouseEvent) => {
        if (searchNode.current!.contains(e.target as Node)) {
            return;
        }
        setResults([]);
    };

    const handleChange = (_e: React.ChangeEvent<HTMLInputElement>, { value }: { value: string }) => {
        setSearchInput(value);
        search(value);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const search = useCallback(
        debounce(async (input: string) => {
            const response = await runSearch(input);
            setResults(response);
        }, 500),
        []
    );

    return (
        <div ref={searchNode}>
            <Input onChange={handleChange} placeholder="Search" value={searchInput} />
            {results.length > 0 && (
                <Card style={{ position: "absolute", zIndex: 400 }}>
                    <Card.Content>
                        <List divided size="large">
                            {results.map((result) => {
                                return (
                                    <List.Item key={result.Value}>
                                        <List.Content>{buildLink(result)}</List.Content>
                                    </List.Item>
                                );
                            })}
                        </List>
                    </Card.Content>
                </Card>
            )}
        </div>
    );
};

export default GlobalSearch;
